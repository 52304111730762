import { UpdatableModel } from './updatable-model';
import { Phone } from './phone';
import { Address } from './address';
import { ArchitectApplication } from './applications/architect-application';
import { Name } from './name';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArchitectLicense } from './architect-license';
import { PersonLite } from './person-lite';
import { ProfessionalReference } from './professional-reference';

export class PersonFull extends UpdatableModel<PersonFull> {
  id: string;
  name: Name;
  credentials: string;
  email: string;
  socialSecurityNumber: string;
  visaNumber: string;
  uscisNumber?: string;
  immigrationStatus: string;
  dateOfBirth: Date;
  gender: string;
  architectApplication: ArchitectApplication;
  primaryPhone: Phone;
  secondaryPhone: Phone;
  firmName: string;
  workAddress: Address;
  homeAddress: Address;
  sendMailTo: string;
  ncarbNumber?: number;
  clarbNumber?: number;
  licenses: ArchitectLicense[];
  pointOfContact: PersonLite;
  hasActiveApplication: boolean;
  references: ProfessionalReference[];

  get isArchitectOrApplicant(): boolean {
    return !!this.ncarbNumber || !!this.clarbNumber || !!this.licenses.length || !!this.hasActiveApplication;
  }

  get nameWithCredentials(): string {
    let name = this.name.full;
    if (this.credentials) {
      name += ', ' + this.credentials;
    }
    return name;
  }

  update(init?: Partial<PersonFull>) {
    super.update(init);
    let _ = ['primaryPhone', 'secondaryPhone'].forEach(p => {
      if (this[p]) {
        this[p] = new Phone(this[p]);
      }
    });

    if (this.pointOfContact) {
      this.pointOfContact = new PersonLite(this.pointOfContact);
    }
  }

  acceptPatch(formGroup: FormGroup) {
    let formInfo = Object.assign({}, formGroup.value);
    this.update(formInfo);
    this.cleanup();
  }

  cleanup() {
    let _ = ['primaryPhone', 'secondaryPhone'].forEach(p => {
      if (!this[p] || !this[p].number) {
        delete this[p];
      } else {
        this[p] = new Phone(this[p]);
      }
    });
    _ = ['workAddress', 'homeAddress'].forEach(a => {
      if (!this[a] || !this[a].streetLine1) {
        delete this[a];
      } else {
        this[a] = new Address(this[a]);
      }
    });
  }

  patch(formGroup: FormGroup) {
    this.cleanup();
    formGroup.patchValue(this);
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      name: Name.GetFormGroup(formBuilder),
      credentials: '',
      email: '',
      socialSecurityNumber: '',
      visaNumber: '',
      uscisNumber: null,
      immigrationStatus: '',
      dateOfBirth: '',
      gender: '',
      primaryPhone: Phone.GetFormGroup(formBuilder),
      secondaryPhone: Phone.GetFormGroup(formBuilder),
      firmName: '',
      sendMailTo: '',
      ncarbNumber: '',
      clarbNumber: '',
    });
  }
}
