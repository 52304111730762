import { UpdatableModel } from './updatable-model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Address } from './address';
import { Phone } from './phone';
import { FirmLicense } from './firm-license';
import { SelectOption } from './select-option';

export class Firm extends UpdatableModel<Firm> {
  id: string;
  name: string;
  nameFull: string;
  doingBusinessAs: string;
  corporateTitle: number;
  email: string;
  website: string;
  primaryPhone: Phone;
  secondaryPhone: Phone;
  publicAddress: Address;
  pointOfContactId: string;
  doesPointOfContactNeedVerification: boolean;
  license: FirmLicense;

  update(init?: Partial<Firm>) {
    super.update(init);
    if (this.license && !this.license.update) {
      this.license = new FirmLicense(this.license);
    }
    if (this.primaryPhone) {
      this.primaryPhone = new Phone(this.primaryPhone);
    }
    if (this.secondaryPhone) {
      this.secondaryPhone = new Phone(this.secondaryPhone);
    }
    if (this.publicAddress) {
      this.publicAddress = new Address(this.publicAddress);
    }
  }

  get hasPointOfContact() {
    return !!this.pointOfContactId;
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      name: '',
      doingBusinessAs: '',
      corporateTitle: '',
      email: '',
      website: '',
      primaryPhone: Phone.GetFormGroup(formBuilder),
      secondaryPhone: Phone.GetFormGroup(formBuilder),
      publicAddress: Address.GetFormGroup(formBuilder),
    });
  }

  static CorporateTitles = [
    new SelectOption({
      text: 'A Professional Architecture Corporation',
      value: 'ProfessionalArchitectureCorporation',
    }),
    new SelectOption({ text: 'A Professional Corporation', value: 'ProfessionalCorporation' }),
    new SelectOption({ text: 'An Architectural Corporation', value: 'ArchitecturalCorporation' }),
    new SelectOption({ text: 'PAC', value: 'PAC' }),
    new SelectOption({ text: 'APAC', value: 'APAC' }),
    new SelectOption({ text: 'APC', value: 'APC' }),
  ];

  acceptPatch(formGroup: FormGroup) {
    this.update(formGroup.value);
    this.cleanup();
    if (this.publicAddress && !this.publicAddress.streetLine1) {
      delete this.publicAddress;
    }
  }

  cleanup() {
    ['publicAddress'].forEach(a => {
      if (!this[a]) {
        delete this[a];
      } else {
        this[a] = new Address(this[a]);
      }
    });
    let _ = ['primaryPhone', 'secondaryPhone'].forEach(p => {
      if (!this[p] || !this[p].number) {
        delete this[p];
      } else {
        this[p] = new Phone(this[p]);
      }
    });
  }

  patch(formGroup: FormGroup) {
    this.cleanup();
    formGroup.patchValue(this);
  }
}
