import { JurisdictionConstants } from './jurisdiction-constants';
import { Profession } from './profession';

export const LAConstants: JurisdictionConstants = {
  legal:
    'The legislature has enacted laws which govern the practice of architecture in Louisiana. In accordance with those laws, the LSBAE has adopted rules which regulate the registration of individuals practicing architecture. To register as an architect, one must meet the education, experience and examination requirements <a target="_blank" href="https://lsbae.com/licensure/laws-and-rules/">LA R.S 146:1</a>. Additionally, architects renewing their license have continuing education requirements that must be met on an annual basis. For more information see <a target="_blank" href="https://lsbae.com/licensure/laws-and-rules/">Board Rule §1315</a>.',
  acronym: 'LSBAE',
  workAddressSuffix: '(Public)',
  homeAddressSuffix: '(Private)',
  commonLinks: {
    publicSite: 'https://www.lsbae.com/',
    publicUrl: 'www.lsbae.com',
    registrationInfo: 'https://lsbae.com/licensure/licensing-registration/',
    rules: 'https://lsbae.com/consumer-info/laws-rules/',
    centuryCode: null,
    lapels: 'http://www.lapels.com/docs/Applications/firm/firm_application.pdf',
    architectLookup: 'https://lsbae.com/consumer-info/find-an-architect/',
    firmLookup: 'https://lsbae.com/consumer-info/find-a-firm/',
  },
  manualProfessions: [Profession.Architect],
  architectTabName: 'Architects',
  ceRule: {
    section: '§1315.D',
    exemption: '§1315.B',
  },
  timezoneAbbreviation: 'CT',
  checkAddress: {
    address1: 'LSBAE',
    address2: '9625 Fenway Ave., Ste. B',
    cityStateZip: 'Baton Rouge, LA 70809-1592',
  },
  firmApplicationSettings: {
    askForStateInFirmSupervisor: true,
    showOldBoardOfDirectors: true,
  },
  applicationStepContent: {
    militaryInfo: {
      question: 'Are you active military or the spouse of an active military member?',
    },
  },
};
